// ------------------------------------------------------------------------------
// KIDIWI Digital property
// Management of the search Table
// ------------------------------------------------------------------------------

import { API } from "aws-amplify";
import { listCategorysAndSubCategorys } from "@/graphql/kdwqueries";
import i18n from "@/plugins/i18n.js";
import { formatCategoryOrSubCategoryName } from "@/helpers/Formatter";

export async function fetchSearchTable(tableSearchCategory) {
  try {
    // console.log('fetch category table.....')
    // console.log('locale:' + i18n.locale)
    var apiData;
    var nextTokenStart = "start";
    var listFromAPI;
    var i = 0;
    var j = 0;
    // var k = 0
    var found = false;
    var subCatlistFromAPI;
    var catName, subCatName, keyWordName;
    // var stringToObject

    // We empty the table.
    tableSearchCategory.splice(0, tableSearchCategory.length);

    while (nextTokenStart !== null) {
      if (nextTokenStart === "start") {
        // console.log('list search Table before.')
        apiData = await API.graphql({
          query: listCategorysAndSubCategorys,
          variables: { limit: 4000 },
          authMode: "AWS_IAM",
        });
        // console.log('list search Table.')
      } else {
        apiData = await API.graphql({
          query: listCategorysAndSubCategorys,
          variables: { limit: 4000, nextToken: nextTokenStart },
          authMode: "AWS_IAM",
        });
      }
      // console.log('apiData')
      // console.log(apiData)
      listFromAPI = apiData.data.listCategories.items;
      // console.log('listFromAPI')
      // console.log(listFromAPI)
      nextTokenStart = apiData.data.listCategories.nextToken;
      if (listFromAPI.length !== 0) {
        Promise.all(
          listFromAPI.map(async (category) => {
            found = false;
            // We are searching for the category name (may depends on the selected language)
            catName = formatCategoryOrSubCategoryName(
              category.name,
              category.countryCode,
              category.nameTag,
            );

            // We are building the list from the sub-category
            subCatlistFromAPI = category.subCategorys.items;
            // console.log(subCatlistFromAPI)
            for (i = 0; i < subCatlistFromAPI.length; i++) {
              j++;
              // We need to check if we need to translate the subcategory.
              subCatName = formatCategoryOrSubCategoryName(
                subCatlistFromAPI[i].name,
                subCatlistFromAPI[i].countryCode,
                subCatlistFromAPI[i].nameTag,
              );
              tableSearchCategory.push({
                keyword: subCatName + ", " + catName,
                categoryid: category.id + "," + j,
                subCat: subCatName,
                subCatId: subCatlistFromAPI[i].id,
              });
              found = true;
            }
            // We are building the list from the keywords
            for (i = 0; i < category.keywords.length; i++) {
              j++;
              keyWordName = i18n.t(category.keywords[i]);
              tableSearchCategory.push({
                keyword: keyWordName + ", " + catName,
                categoryid: category.id + "," + j,
                subCat: "",
                subCatId: "",
              });
              found = true;
            }
            if (found === false) {
              // console.log('No item found - This is a new category freshly created alone ------------------------' + catName)
              j++;
              tableSearchCategory.push({
                keyword: catName + ", " + catName,
                categoryid: category.id + "," + j,
                subCat: "",
                subCatId: "",
              });
            }
          }),
        );
      }
    }
  } catch (err) {
    console.log(err);
    console.log(
      "FAIL to get the Search table data .................................................................",
    );
  }
}
/*
async function createSearchTableitem (id, keywordname, category) {
    try {
        // console.log('try to create ' + keywordname)
        const searchObject = { categoryid: id, keyword: keywordname + ' ' + category }
        await API.graphql({ query: createSearchCategoryMutation, variables: { input: searchObject }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
        // console.log('created: ' + keywordname)
    } catch {
        console.log('FAIL to create a search item in search category table ........................')
    }
}

export async function populateSearchTable () {
    try {
        console.log('try to populate the search table')
        var apiData
        var nextTokenStart = 'start'
        var listFromAPI
        var i = 0

        while (nextTokenStart !== null) {
            if (nextTokenStart === 'start') {
                // console.log('list search Table.')
                apiData = await API.graphql({ query: listCategorys, authMode: 'AWS_IAM' })
                // console.log('list search Table..')
            } else {
                apiData = await API.graphql({ query: listCategorys, variables: { limit: 4000, nextToken: nextTokenStart }, authMode: 'AWS_IAM' })
            }
            listFromAPI = apiData.data.listCategorys.items
            nextTokenStart = apiData.data.listCategorys.nextToken
            // console.log('nextTokenStart-------------------------')
            // console.log(nextTokenStart)
            // console.log('nextTokenStart-------------------------')
            Promise.all(listFromAPI.map(async category => {
            if (category.name) {
                // console.log(category.name === 'Ascenseur')
                // console.log(category.keywords.length)
                for (i = 0; i < category.keywords.length; i++) {
                  createSearchTableitem(category.id, category.keywords[i], category.name)
                }
            }
            }))
        }

        // const myPostalCode = { codeINSEE: postalCodeObject.Code_commune_INSEE, townName: postalCodeObject.Nom_commune, postalCode: postalCodeObject.Code_postal, ligne5: postalCodeObject.Ligne_5, distributionData: postalCodeObject.Libellé_d_acheminement, gps1: postalCodeObject.coordonnees_gps1, gps2: postalCodeObject.coordonnees_gps2 }
        // const myPostalCode = { codeINSEE: postalCodeObject.Code_commune_INSEE, townName: postalCodeObject.Nom_commune, postalCode: postalCodeObject.Code_postal, latitude: postalCodeObject.coordonnees_gps1, longitude: postalCodeObject.coordonnees_gps2, countryCode: 'fr' }
        // console.log(myPostalCode)
        // await API.graphql({ query: createPostalCodeMutation, variables: { input: myPostalCode }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
        // console.log('Postal code created' + postalCodeObject.Code_commune_INSEE)
    } catch {
        console.log('FAIL to populate the search table ........................')
    }
}

export async function deleteSearchTableContent () {
    try {
            console.log('delete search table')
            var apiData
            var nextTokenStart = 'start'
            var listFromAPI
            var listToDelete = []

            while (nextTokenStart !== null) {
            if (nextTokenStart === 'start') {
                apiData = await API.graphql({ query: listSearchCategorys, variables: { limit: 4000 }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
                console.log('list search Table.')
            } else {
                apiData = await API.graphql({ query: listSearchCategorys, variables: { limit: 4000, nextToken: nextTokenStart }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
            }
            console.log('list search Table..')
            console.log(apiData)
            console.log('list search Table...')
            listFromAPI = apiData.data.listSearchCategorys.items
            // console.log(listPostalCodesFromAPI)
            nextTokenStart = apiData.data.listSearchCategorys.nextToken
            // console.log('nextTokenStart-------------------------')
            // console.log(nextTokenStart)
            // console.log('nextTokenStart-------------------------')
            if ((listFromAPI.length === 0)) {
                return
            }
            // console.log('find some postal code')
            Promise.all(listFromAPI.map(async searchitem => {
                // console.log('deleting: ' + searchitem.keyword)
                listToDelete.push(searchitem.id)
                // await API.graphql({ query: deleteSearchCategoryMutation, variables: { input: { id: searchitem.id } }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
            }))
            }
            console.log(listToDelete)
            for (var i = 0; i < listToDelete.length; i++) {
                await API.graphql({ query: deleteSearchCategoryMutation, variables: { input: { id: listToDelete[i] } }, authMode: 'AMAZON_COGNITO_USER_POOLS' })
            }
            // console.log(listToDelete)
            return
    } catch {
        console.log('FAIL to empty the search table .................................................................')
    }
}
*/
